import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import BorderedBox from '../components/borderedbox'
import TitleHeader from '../components/titleheader'
import HPCardTile from '../components/hpcardtile'
import heartimage from '../assets/images/homepage/CorprateHp_16.jpg'
import headerimage from '../assets/images/homepage/CorprateHp-slider.gif'
import mbheaderimage from '../assets/images/homepage/CorprateHp-slider-m.gif'
import tileimage1 from '../assets/images/homepage/CorprateHp_07.jpg'
import tileimage2 from '../assets/images/homepage/CorprateHp_09.jpg'
import tileimage3 from '../assets/images/homepage/CorprateHp_11.jpg'
import esgPdf2022 from '../assets/images/TCP-2022-ESG-Report-Final.pdf'
import esghpdk from '../assets/images/esg_2021/hp_header_dk.jpg'
import esghpmb from '../assets/images/esg_2021/hp_header_mb.jpg'

import { trackPageData, getPageDetails } from '../analytics'

class IndexPage extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }

    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="The Children’s Place Corporate Website"
          description="The Children’s Place is the largest pure-play children’s specialty apparel retailer in North America. Find out more on The Children's Place Corporate Website."
          keywords={[`children`, `clothing`, `childrens place`]}
        />

        {/* Page Title & Header Image */}

        <div class="row mb-5">
          <br />
          <br className="visible-xs" />

          <a href={`${esgPdf2022}`} target="_blank">
            <div class="col-12">
              <img src={esghpdk} className="img-fluid hidden-xs" alt="" />
              <img src={esghpmb} className="img-fluid visible-xs" alt="" />
            </div>
          </a>
          <div class="col-12">
            <TitleHeader
              image={headerimage}
              mbheaderimage={mbheaderimage}
              title="LOVE THIS PLACE"
            />
            <p class="text-center pt-2">
              The largest pure-play children’s specialty apparel retailer now{' '}
              <br class="d-none d-md-inline-block" />
              offers consumers four brands to shop – The Children’s Place,
              Gymboree, Sugar &amp; Jade and PJ Place.
            </p>
          </div>
        </div>
        {/* End Page Title & Header Image */}

        {/* Three Tiles */}
        <div class="row mb-5">
          <div class="col-md-4 col-sm-12">
            <HPCardTile
              image={tileimage1}
              link="/who-we-are"
              title="WHO WE ARE"
              description={[
                'Our brand mission and best-in-class management team have established ',
                <br class="d-none d-md-inline-block" />,
                'us as an industry leader.',
              ]}
            >
              <Link to="/about-us">
                <button type="button" class="btn btn-primary">
                  About Us
                </button>
              </Link>
              <Link to="/senior-leadership">
                <button type="button" class="btn btn-primary">
                  Senior Leadership
                </button>
              </Link>
              {/* <Link to="/board-of-directors">
                <button type="button" class="btn btn-primary">Board of Directors</button>
              </Link> */}
              <Link to="/international-franchises">
                <button type="button" class="btn btn-primary">
                  International Franchises
                </button>
              </Link>
            </HPCardTile>
          </div>
          <div class="mb-5 col-12 d-md-none"></div>
          <div class="col-md-4 col-sm-12">
            <HPCardTile
              image={tileimage2}
              title="CAREERS"
              link="/careers"
              description="Make our PLACE yours. Learn about our job opportunities and apply today."
            >
              <Link to="/careers#corporate">
                <button type="button" class="btn btn-primary">
                  Corporate
                </button>
              </Link>
              <Link to="/careers#stores">
                <button type="button" class="btn btn-primary">
                  Stores
                </button>
              </Link>
              <Link to="/careers#distribution-center">
                <button type="button" class="btn btn-primary">
                  Distribution Center
                </button>
              </Link>
              <Link to="/careers#international">
                <button type="button" class="btn btn-primary">
                  International
                </button>
              </Link>
              <Link to="/us-internships">
                <button type="button" class="btn btn-primary">
                  US Internships
                </button>
              </Link>
              <Link to="/hong-kong-internships">
                <button type="button" class="btn btn-primary">
                  Hong Kong Internships
                </button>
              </Link>
            </HPCardTile>
          </div>
          <div class="mb-5 col-12 d-md-none"></div>
          <div class="col-md-4 col-sm-12">
            <HPCardTile
              image={tileimage3}
              title="INVESTOR RELATIONS"
              alink="http://investor.childrensplace.com/"
              description="The Children’s Place (PLCE) is the largest pure-play children’s specialty apparel retailer in North America and trades on the NASDAQ."
            >
              <a href="http://investor.childrensplace.com/" target="_blank">
                <button type="button" class="btn btn-primary">
                  Corporate Overview
                </button>
              </a>
              <a
                href="http://investor.childrensplace.com/financial-information/annuals-proxies"
                target="_blank"
              >
                <button type="button" class="btn btn-primary">
                  Financial Reports
                </button>
              </a>
              <a
                href="http://investor.childrensplace.com/news-events/press-releases"
                target="_blank"
              >
                <button type="button" class="btn btn-primary">
                  Press Releases
                </button>
              </a>
            </HPCardTile>
          </div>
        </div>
        {/* End Three Tiles */}

        {/* Corporate Responsibility */}
        <div class="row mb-5">
          <div class="col-md-4 col-sm-12 text-center">
            <img src={heartimage} className="img-fluid" alt="" />
          </div>
          <div class="mb-3 col-12 d-md-none"></div>
          <div class="col-md-8 col-sm-12 text-center">
            <BorderedBox>
              <Link className="nav-link" to="/esg" activeClassName="active">
                <h2>Environment, Social &amp; Governance</h2>
              </Link>
              <p>
                We are committed to contributing <br class="d-md-none" /> to a
                healthy planet and equitable
                <br class="d-md-none" /> society for
                <br class="d-none d-md-inline-block" /> the benefit of future
                generations.
              </p>

              <div class="row">
                <div class="col-sm-4 col-xs-12">
                  <Link to="/environment">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-block"
                    >
                      Environment
                    </button>
                  </Link>
                </div>
                <div class="col-sm-4 col-xs-12">
                  <Link to="/social">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-block"
                    >
                      Social
                    </button>
                  </Link>
                </div>
                <div class="col-sm-4 col-xs-12">
                  <Link to="/governance">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-block"
                    >
                      Governance
                    </button>
                  </Link>
                </div>
              </div>
            </BorderedBox>
          </div>
        </div>

        {/* End Corporate Responsibility */}
      </Layout>
    )
  }
}

export default IndexPage
