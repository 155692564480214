
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'



const HPCardTile = ({ children, image, title, description, link, alink }) => (
    <div class="text-center card-tile pl-2 pr-2">
         <img src={image} className="img-fluid mb-4" alt="" />
         {link &&<Link to={link}><h3>{title}</h3></Link>}
         {alink &&<a target="_blank" href={alink}><h3>{title}</h3></a>}
         <p class="mb-4">{description}</p>
        {children}
    </div>
)

HPCardTile.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.node,
    alink: PropTypes.node,
    image: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
}

export default HPCardTile
